import page1 from "../images/ads/page1.jpg";
import page2 from "../images/ads/page2.jpg";
import page3 from "../images/ads/page3.jpg";
import page4 from "../images/ads/page4.jpg";
import page5 from "../images/ads/page5.jpg";
import page6 from "../images/ads/page6.jpg";
import page7 from "../images/ads/page7.jpg";
import page8 from "../images/ads/page8.jpg";

import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Pagination, Navigation } from "swiper/modules";

export default function Slides() {
  return (
    <>
      <Swiper
        autoHeight={true}
        loop={true}
        style={{
          "--swiper-navigation-color": "#268E56",
          "--swiper-pagination-bottom": " 0px",
          "--swiper-pagination-color": "#268E56",
        }}
        pagination={{
          type: "fraction",
        }}
        navigation={true}
        modules={[Pagination, Navigation]}
        className="mySwiper flex object-contain max-w-xl"
      >
        <SwiperSlide>
          <Slide src={page1} />
        </SwiperSlide>
        <SwiperSlide>
          <Slide src={page2} />
        </SwiperSlide>
        <SwiperSlide>
          <Slide src={page3} />
        </SwiperSlide>
        <SwiperSlide>
          <Slide src={page4} />
        </SwiperSlide>
        <SwiperSlide>
          <Slide src={page5} />
        </SwiperSlide>
        <SwiperSlide>
          <Slide src={page6} />
        </SwiperSlide>
        <SwiperSlide>
          <Slide src={page7} />
        </SwiperSlide>
        <SwiperSlide>
          <Slide src={page8} />
        </SwiperSlide>
      </Swiper>
    </>
  );
}

function Slide(props) {
  return (
    <div className="flex justify-center">
      <img src={props.src} className="h-auto block w-full object-contain" />
    </div>
  );
}
