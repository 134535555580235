import { Link } from "react-router-dom";

export default function Submitted() {
  return (
    <div className="flex flex-col  min-h-screen justify-center content-start align-top items-center gap-3 m-8">
      <h2>Your message has been sent.</h2>
      <Link to="/" className="text-xl font-Montserrat">
        Return home
      </Link>
    </div>
  );
}
