//Home.js

import PdfLink from "../components/PdfLink";

import Slides from "../components/Slides";
export default function Home() {
  return (
    <div className="w-full min-h-screen flex-col justify-center align-center items-center mx-3">
      <Ad />
    </div>
  );
}

function Ad() {
  return (
    <div className="flex flex-col lg:flex-row items-center h-auto">
      <div className="flex lg:basis-1/3 text-center gap-3">
        <div>
          <PdfLink title="This week's sales" Pdf="/flyer.pdf" />
          <p>Friday, June 14 - Thursday, June 20</p>
        </div>
      </div>
      <div className=" flex w-full lg:basis-2/3 ">
        <Slides />
      </div>
    </div>
  );
}
