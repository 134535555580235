import "./App.css";
import { Route, Routes } from "react-router-dom";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import ContactUs from "./pages/Contact";
import Submitted from "./pages/Submitted";
import ReturnPolicy from "./pages/Returns";
function App() {
  return (
    <div className="App">
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contact" element={<ContactUs />} />
        <Route path="/returns" element={<ReturnPolicy />} />
        <Route path="/submitted" element={<Submitted />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
