import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { faFacebook, faInstagram } from "@fortawesome/free-brands-svg-icons";

const socials = {
  instagram: "https://www.instagram.com/patsfarms?igsh=eGg3eTNzMGthenhp",
  facebook: "https://www.facebook.com/patsfarms123",
};

export const SocialLinks = () => {
  return (
    <div className="flex flex-row gap-8 justify-end">
      <div>
        <Link to={socials.facebook} target="_blank">
          <FontAwesomeIcon
            icon={faFacebook}
            size="xl"
            color="black"
            className="hover:fill-slate-400"
          />
        </Link>
      </div>
      <div>
        <Link to={socials.instagram} target="_blank">
          <FontAwesomeIcon icon={faInstagram} size="xl" />
        </Link>
      </div>
    </div>
  );
};

export const Links = () => {
  return (
    <div className="flex flex-row gap-8 justify-start">
      <Link
        to="/contact"
        className="font-Montserrat text-sm hover:underline hover:underline-offset-4 hover:decoration-lime-700"
      >
        Contact Us
      </Link>
      <Link
        to="/returns"
        className="font-Montserrat text-sm hover:text-lime-800 hover:font-medium"
      >
        Return Policy
      </Link>
    </div>
  );
};

export default function Footer() {
  return (
    <footer className="footer p-10 bg-Westar text-black-content items-center content-center justify-around">
      <div className="flex flex-col gap-4">
        <div className="grid grid-cols-10">
          <div className="col-span-7">
            <Links />
          </div>
          <div className="col-span-3">
            <SocialLinks />
          </div>
        </div>

        <p className="text-xs text-gray-400 md:text-sm text-center">
          Copyright 2024 &copy; All Rights Reserved
        </p>
      </div>
    </footer>
  );
}
