export default function ReturnPolicy() {
  return (
    <div className="w-full min-h-screen flex-col justify-center align-center items-center mx-10 px-20 gap-1">
      <h2 className="text-center mb-2">Return Policy</h2>
      <div className="text- gap-2  flex flex-col  justify-center place-content-center text-center">
        <p className="">
          Perishable items must be returned within 24 hours of purchase with a
          receipt.
        </p>

        <p className="">
          Non-perishable items must be returned within 7 days of purchase with a
          receipt.
        </p>
        <p className="mt-2">
          Please call (516) 481-5525 with any further questions.
        </p>
      </div>
    </div>
  );
}
